import { Input } from "antd";
import jwt_decode from "jwt-decode";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "@react-oauth/google";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import fb from "../../../assets/images/login/staff/fb.jpg";
import ToastPopup, { notifyError, notifySuccess } from "~/toast/toast";
import ButtonComponent from "~/components/layouts/components/Button";
import { isAdmin, isCustomer, isEnterprise } from "~/utils/utils";
import ModalRegister from "~/components/Modal/ModalRegister";
import packageApi from "~/api/Package/packageApi";
import images from "~/assets/images/login/staff";
import styles from "./Login.module.scss";
import usersApi from "~/api/usersApi";

const cx = classNames.bind(styles);

function Login() {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberChecked, setRememberChecked] = useState(false);
  const [currentModal, setCurrentModal] = useState(false);
  const navigate = useNavigate();

  const { isAuth } = useSelector((state) => state.userReducer);

  const account =
    localStorage.getItem("account") === null
      ? null
      : JSON.parse(localStorage.getItem("account"));

  useEffect(() => {
    if (account?.checked) {
      setUsername(account?.user_name);
      setPassword(account?.password);
      setRememberChecked(account?.checked);
    }
  }, []);

  useEffect(() => {
    if (isCustomer() && isAuth && navigate("/"));
    if (isAdmin() && isAuth && navigate("/businessmanage/listbusiness"));
    if (isEnterprise() && isAuth && navigate("/"));
  }, [isAuth, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await usersApi.login({
        user_name: username.trim(),
        password: password.trim(),
      });
      if (res.message === "error")
        notifyError("Đăng nhập không thành công, Vui lòng nhập lại!");
      else {
        notifySuccess("Đăng nhập thành công");

        localStorage.setItem("user", JSON.stringify(res));
        localStorage.setItem("access_token", JSON.stringify(res.access_token));
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(res.refresh_token)
        );
        localStorage.setItem("info", JSON.stringify(res));
        const resPackage = await packageApi.getPackageRegistered();
        localStorage.setItem("package", JSON.stringify(resPackage));

        if (rememberChecked) {
          localStorage.setItem(
            "account",
            JSON.stringify({
              user_name: username.trim(),
              password: password.trim(),
              checked: rememberChecked,
            })
          );
        } else {
          localStorage.removeItem("account");
        }

        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      notifyError("Đăng nhập không thành công");
    }
  };

  const handleClose = (e) => {
    setCurrentModal(false);
  };

  const handleSubmit = async (response) => {
    var user = jwt_decode(response.credential);
    const data = {
      user: {
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
        id: user.sub,
        name: user.name,
        photo: user.name,
      },
    };

    const res = await usersApi.loginGoolge(data);
    if (res.message === "error")
      notifyError("Đăng nhập không thành công, Vui lòng nhập lại!");
    else {
      notifySuccess("Đăng nhập thành công");

      localStorage.setItem("user", JSON.stringify(res));
      localStorage.setItem("access_token", JSON.stringify(res.access_token));
      localStorage.setItem("refresh_token", JSON.stringify(res.refresh_token));
      localStorage.setItem("info", JSON.stringify(res));
      const resPackage = await packageApi.getPackageRegistered();
      localStorage.setItem("package", JSON.stringify(resPackage));

      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  };

  const responseFacebook = async (response) => {
    if (response.status !== "unknown") {
      const data = {
        accessToken: response.accessToken,
        email: response.email,
        name: response.name,
        picture: response.picture,
        id: response.id,
      };

      if (data.id !== undefined) {
        const res = await usersApi.loginFacebook(data);
        if (res.message === "error")
          notifyError("Đăng nhập không thành công, Vui lòng nhập lại!");
        else {
          notifySuccess("Đăng nhập thành công");
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem(
            "access_token",
            JSON.stringify(res.access_token)
          );
          localStorage.setItem(
            "refresh_token",
            JSON.stringify(res.refresh_token)
          );
          localStorage.setItem("info", JSON.stringify(res));
          const resPackage = await packageApi.getPackageRegistered();
          localStorage.setItem("package", JSON.stringify(resPackage));
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      }
    }
  };

  const renderGoogleButton = ({ onClick }) => (
    <button onClick={onClick}>
      <img src="path-to-google-logo.png" alt="Google Logo" />
    </button>
  );

  return (
    <div className={cx("wrapper")}>
      <ModalRegister visible={currentModal} onHide={handleClose} />
      <div className={cx("container")}>
        <ToastPopup />
        <div
          style={{
            background: "white",
            padding: "50px 30px 30px 30px",
            borderRadius: "12px",
          }}
        >
          <div className={cx("content-login")}>
            <div className={cx("content-right")}>
              <span className={cx("title")}>{t("login.title")}</span>
              <form
                className={cx("login-form")}
                action=""
                onSubmit={handleLogin}
              >
                <div className={cx("form-item-input")}>
                  <img src={images.UserIcon} alt="icon" width="24px" />
                  <Input
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "transparent",
                      border: "none",
                      boxShadow: "none",
                      fontSize: "16px",
                    }}
                    value={username}
                    id={cx("username")}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    placeholder={t("login.username")}
                    type="text"
                    autoComplete="false"
                  ></Input>
                </div>
                <div className={cx("form-item-input")}>
                  <img src={images.PasswordIcon} alt="icon" width="24px" />
                  <Input.Password
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "transparent",
                      border: "none",
                      boxShadow: "none",
                      fontSize: "16px",
                      color: "black",
                    }}
                    id={cx("password")}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder={t("login.password")}
                    value={password}
                    type="password"
                    iconRender={(visible) =>
                      visible === true ? (
                        <EyeOutlined
                          style={{ color: "#737373", fontSize: "2rem" }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ color: "#737373", fontSize: "2rem" }}
                        />
                      )
                    }
                    autoComplete="false"
                  ></Input.Password>
                </div>
                <div className={cx("password-action")}>
                  <div className={cx("remember-password")}>
                    <input
                      style={{
                        borderRadius: "8px",
                      }}
                      id={cx("btn-checkbox")}
                      checked={rememberChecked}
                      onChange={() => {
                        setRememberChecked(!rememberChecked);
                      }}
                      type="checkbox"
                    />
                    <span>{t("login.repassword")}</span>
                  </div>
                  <ButtonComponent
                    to="/login/forgetpassword"
                    className={cx("forgot-password")}
                  >
                    <p>{t("login.fopassword")}</p>
                  </ButtonComponent>
                </div>
                <div>
                  <ButtonComponent
                    primary4
                    style={{
                      minWidth: "400px",
                      background: "#25457C",
                      borderRadius: "10px",
                    }}
                    className={cx("btn-login")}
                    type="submit"
                  >
                    {t("login.login")}
                  </ButtonComponent>
                </div>
              </form>
            </div>
          </div>
          <div className={cx("content-login-social")}>
            <div className={cx("content-login-social-detail")}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleSubmit(credentialResponse);
                }}
                icon
                onError={() => {
                  console.log("Login Failed");
                }}
                useOneTap
              />
              {/* <FacebookLogin
                appId="582180726997209"
                textButton="Login"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <button onClick={renderProps.onClick}>
                   <FontAwesomeIcon
                      style={{
                        fontSize: "1.8rem",
                        marginRight: "8px",
                      }}
                      icon={faFacebook}
                    />
                    <img src={fb} alt="" width="40px" />
                  </button>
                )}
              /> */}
            </div>
            <div className={cx("content-login-register")}>
              <p>{t("login.account")}</p>
              <p
                className={cx("register")}
                onClick={() => {
                  setCurrentModal(true);
                }}
              >
                {t("login.register")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;

import { useEffect } from "react";
import { Input, Spin } from "antd";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLikeNews, getNewsById } from "~/store/News/newsSlice";
import {
  faFacebook,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import "./NewDetails.scss";

const NewsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { newsById, listLikeNews, loading } = useSelector(
    (state) => state.newsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsById(id));
    dispatch(getLikeNews(5));
  }, [dispatch, id]);

  return (
    <div className="news-details-wrapper">
      <Spin spinning={loading}>
        {newsById.length !== undefined ? (
          <></>
        ) : (
          <div className="inner">
            <div className="social-network-group">
              <FontAwesomeIcon icon={faFacebook} className="icon" />
              <FontAwesomeIcon icon={faTwitter} className="icon" />
              <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
              <FontAwesomeIcon icon={faMessage} className="icon" />
            </div>
            <div className="news-content">
              <h1>{newsById.name}</h1>
              {/* <div className="news-category">
                {newsById?.news_categories?.name}
              </div> */}
              <button className="in-progress">
                {newsById?.news_categories?.name}
              </button>
              <div className="image-wrapper">
                <img
                  src={`${process.env.REACT_APP_API_URL}${newsById?.image_url[0]?.url}`}
                  alt=""
                />
              </div>
              <div className="desc">{newsById?.des}</div>
              <div className="news-content">
                {newsById.content === null ? "" : parse(newsById?.content)}
              </div>
              <div className="comment-group">
                <h3>Add your comment</h3>
                <Input.TextArea></Input.TextArea>
                <button>Post Comment</button>
              </div>
            </div>
            <div className="most-watch">
              <h2 className="primary-color title-spacing">
                {t("text.Most_popular_post")}
              </h2>
              <div className="list-news">
                {listLikeNews.map((item) => {
                  return (
                    <div className="news-item">
                      <span
                        className="news-title"
                        onClick={() => {
                          navigate(`/news/${item?.id}`);
                        }}
                      >
                        {item?.name}
                      </span>
                      <div className="bottom">
                        <span className="category">
                          {item?.news_categories === undefined
                            ? ""
                            : item?.news_categories.name}
                        </span>
                        <span className="time">
                          {" "}
                          {item.created_date.substring(0, 10)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default NewsDetails;

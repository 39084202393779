import { useNavigate } from "react-router-dom";
import "./CardNews.scss";

const CardNews = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className="card-news-item">
      <div className="card-news-left">
        <div className="new-news">
          {/* <div className="category"></div> */}

          <div className="new-news-content">
            <div className="new-news-content-left">
          <h3
            className="title"
            onClick={() => {
              navigate(`/news/${item?.id}`);
            }}
          >
            {item?.name}
          </h3>
              <div>
                {" "}
                {/* <button className="status-btn in-progress">
                  {item?.news_categories?.name}
                </button> */}
              </div>
              <span className="des">{item.des}</span>
              <div className="card-bottom">
                <span className="time">
                  {" "}
                  {item?.created_date?.substring(0, 10)}
                </span>
              </div>
            </div>
            <img
              onClick={() => {
                navigate(`/news/${item?.id}`);
              }}
              src={`${process.env.REACT_APP_API_URL}${item.image_url[0]?.url}`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardNews;

import axios from "axios";
import classNames from "classnames/bind";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Form, Input, message as Notify, Modal, Select } from "antd";
import {
  getAllCities,
  getDistrictsById,
  getWardsById,
  setDataWard,
} from "~/store/CustomerProfile/customerSlice";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getStoreById } from "~/store/Store/storeSlice";
import { notifyError, notifySuccess } from "~/toast/toast";
import styles from "./CreateBusiness.module.scss";
import storeApi from "~/api/StoreApi/storeApi";

const { Option } = Select;

const cx = classNames.bind(styles);
const CreateBusiness = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fieldData, setFieldData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dataCity, setDataCity] = useState({});
  const [dataDistrict, setDataDistrict] = useState({});
  const [dataWards, setDataWards] = useState({});
  const [showLogout, setShowLogout] = useState(false);
  const { id } = useParams();
  const [storeData, setStoreData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    image_url: [],
    des: "",
    website: "",
    personal_number: "",
  });
  const { allCities, listDistricts, listWards } = useSelector(
    (state) => state.customerReducer
  );
  const { storeDetail } = useSelector((state) => state.storesReducer);
  let formData = new FormData();
  const [, setFiles] = useState([]);

  useEffect(() => {
    if (isEdit) {
      dispatch(getStoreById(id));
    } else {
      dispatch(getAllCities());
    }
    const getAllField = async () => {
      const result = await storeApi.getAllField();
      setFieldData(result);
    };
    getAllField();
  }, [isEdit, dispatch, id]);

  useEffect(() => {
    if (isEdit) {
      dispatch(getAllCities());
      dispatch(getDistrictsById(storeDetail?.address?.city?.id));
      dispatch(getWardsById(storeDetail?.address?.district?.id));

      if (storeDetail.id !== undefined) {
        const dataEdit = {
          address: storeDetail?.address?.address,
          des: storeDetail.des,
          email: storeDetail.email,
          field_id: storeDetail?.field_id?.id,
          name: storeDetail.name,
          personal_number: storeDetail.personal_number,
          phone: storeDetail.phone,
          website: storeDetail.website,
          cities: storeDetail.address?.city?.id,
          district: storeDetail.address?.district?.id,
          ward: storeDetail.address?.ward?.id,
        };
        form.setFieldsValue(dataEdit);
      }
      setStoreData(storeDetail);
    }
  }, [storeDetail]);

  const handleChangeCity = (value, record) => {
    dispatch(getDistrictsById(value));
    form.setFieldValue("district", undefined);
    form.setFieldValue("ward", undefined);
    setDataCity({
      ...record,
      name: record.children,
      id: record.value,
    });
    dispatch(setDataWard());
  };

  const handleChangeDistrict = (value, record) => {
    dispatch(getWardsById(value));
    form.setFieldValue("ward", undefined);
    setDataDistrict({
      ...record,
      name: record.children,
      id: record.value,
    });
  };

  const handleChangeWards = (value, record) => {
    setDataWards({
      ...record,
      name: record.children,
      id: record.value,
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("info");
    navigate("/login");
  };

  const removeImage = (position) => {
    setStoreData({
      ...storeData,
      image_url: storeData.image_url.filter((_, index) => position !== index),
    });
  };
  const handleFilesChange = async (e) => {
    setFiles(e.target.files);

    for (const key of Object.keys(e.target.files)) {
      formData.append("files", e.target.files[key]);
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload/upload-array`,
        formData
      );
      setFiles([]);

      setStoreData({
        ...storeData,
        image_url: storeData.image_url.concat(
          res.data.url.map((image) => {
            return {
              url: image,
            };
          })
        ),
      });
      notifySuccess("Upload ảnh thành công");
    } catch (error) {
      notifyError("Upload ảnh không thành công");
    }
  };

  const isEmpty = (
    <div className={cx("is-empty")}>
      <h3>Hiện tại chưa có ảnh</h3>
      <input
        type="file"
        multiple
        accept="img/*"
        id="file-upload"
        style={{ display: "none" }}
        onChange={handleFilesChange}
      />
      <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
        Thêm ảnh
      </label>
    </div>
  );
  const isNotEmpty = (arr) => {
    return (
      <div className={cx("image-container")}>
        <div className={cx("big-image")}>
          <div className={cx("delete-image")} onClick={() => removeImage(0)}>
            X
          </div>
          <img
            src={
              arr === undefined
                ? ""
                : `${process.env.REACT_APP_API_URL}/${arr[0]?.url}`
            }
            alt="big"
          />
        </div>
        <div className={cx("small-image-container")}>
          {arr === undefined ? (
            <></>
          ) : (
            arr.map((item, index) => {
              if (index !== 0) {
                return (
                  <div
                    className={cx("small-image")}
                    key={item.url}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.url})`,
                    }}
                  >
                    <div
                      className={cx("delete-image")}
                      onClick={() => removeImage(index)}
                    >
                      X
                    </div>
                  </div>
                );
              }
              return null;
            })
          )}
          <div className={cx("small-image upload")}>
            <input
              className={cx("item__input")}
              type="file"
              id="file-upload"
              multiple
              accept="image/*"
              onChange={handleFilesChange}
            />
            <label htmlFor="file-upload">
              <h4>Thêm ảnh</h4>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const onSubmitStore = async (value) => {
    if (!isEdit) {
      const dataAdd = {
        address: {
          address: value.address,
          city: {
            id: dataCity.id,
            name: dataCity.name,
            ghn_id: dataCity.ghn_id,
          },
          district: {
            id: dataDistrict.id,
            name: dataDistrict.name,
            ghn_id: dataDistrict.ghn_id,
          },
          ward: {
            id: dataWards.id,
            name: dataWards.name,
            ghn_id: dataWards.ghn_id,
          },
        },
        des: value.des,
        email: value.email,
        field_id: value.field_id,
        name: value.name,
        personal_number: value.personal_number,
        phone: value.phone,
        image_url: [storeData.image_url],
        website: value.website === undefined ? "" : value.website,
        ward_code: dataWards.ghn_id,
        district_id: dataDistrict.id,
      };

      const result = await storeApi.createStore(dataAdd);
      if (result.message === "error") {
        Notify.error("Tạo cửa hàng không thành công");
      } else if (result.errors === "USER_HAD_STORE") {
        Notify.error("Bạn đã có cửa hàng");
      } else {
        Notify.success("Tạo cửa hàng thành công");
        setShowLogout(true);
      }
    } else {
      const dataEdit = {
        address: {
          address: value.address,
          city: {
            id:
              dataCity.id === undefined
                ? storeDetail.address.city.id
                : dataCity.id,
            name:
              dataCity.name === undefined
                ? storeDetail.address.city.name
                : dataCity.name,
            ghn_id:
              dataCity.ghn_id === undefined
                ? storeDetail.address.city.ghn_id
                : dataCity.ghn_id,
          },
          district: {
            id:
              dataDistrict.id === undefined
                ? storeDetail.address.district.id
                : dataDistrict.id,
            name:
              dataDistrict.name === undefined
                ? storeDetail.address.district.name
                : dataDistrict.name,
            ghn_id:
              dataDistrict.ghn_id === undefined
                ? storeDetail.address.district.ghn_id
                : dataDistrict.ghn_id,
          },
          ward: {
            id:
              dataWards.id === undefined
                ? storeDetail.address.ward.id
                : dataWards.id,
            name:
              dataWards.name === undefined
                ? storeDetail.address.ward.name
                : dataWards.name,
            ghn_id:
              dataWards.ghn_id === undefined
                ? storeDetail.address.ward.ghn_id
                : dataWards.ghn_id,
          },
        },
        des: value.des,
        email: value.email,
        field_id: value.field_id,
        name: value.name,
        personal_number: value.personal_number,
        phone: value.phone,
        image_url: storeData.image_url.map((item) => item.url),
        website: value.website === undefined ? "" : value.website,
        ward_code:
          dataWards.ghn_id === undefined
            ? storeDetail.address.ward.ghn_id
            : dataWards.ghn_id,
        district_id:
          dataDistrict.id === undefined
            ? storeDetail.address.district.id
            : dataDistrict.id,
      };

      const res = await storeApi.editStore({
        id: id,
        body: dataEdit,
      });

      if (res.status === 200) {
        Notify.success("Chỉnh sửa thành công");
      } else {
        Notify.error("Chỉnh sửa không thành công");
      }
    }
  };

  return (
    <main className={cx("wrapper")}>
      <Modal onOk={handleLogout} visible={showLogout} name="reg-success">
        <h2 className="primary-color title-spacing">Bấm OK để đăng xuất</h2>
      </Modal>
      <div className={cx("inner")}>
        <div className={cx("header-main")}>
          <div className={cx("header-title")}>
            <span>{t("text.company")}</span>
            <FontAwesomeIcon icon={faAngleRight} />
            <span>{t("text.add_company")}</span>
          </div>
        </div>
        <div className={cx("content-main")}>
          <h3>{t("text.basic_information")}</h3>
          <div className={cx("create-business-form")}>
            <Form onFinish={onSubmitStore} form={form}>
              <div className={cx("form-wrapper")}>
                <div className={cx("form-top")}>
                  <div className={cx("form-right")}>
                    <div className={cx("form-right-group")}>
                      <label htmlFor="name">
                        {t("register.form1.nameenterprise")}
                      </label>
                      <Form.Item
                        className={cx("form-group")}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Tên doanh nghiệp không được để trống",
                          },
                        ]}
                      >
                        <Input
                          className={cx("input-7")}
                          placeholder={t("text.enter_name")}
                        />
                      </Form.Item>
                    </div>

                    <div className={cx("form-right-group")}>
                      <label htmlFor="email">Email</label>
                      <Form.Item
                        className={cx("form-group")}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email không được để trống",
                          },
                          {
                            type: "email",
                            message: "Email không đúng định dạng",
                          },
                        ]}
                      >
                        <Input
                          className={cx("input-7")}
                          placeholder={t("login.forgetpassword.detail")}
                        />
                      </Form.Item>
                    </div>

                    <div className={cx("form-right-group")}>
                      <label htmlFor="address">
                        {t("register.form1.address")}
                      </label>
                      <Form.Item
                        className={cx("form-group")}
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Địa chỉ không được để trống",
                          },
                        ]}
                      >
                        <Input
                          className={cx("input-7")}
                          placeholder={t("text.enter_address")}
                        />
                      </Form.Item>
                    </div>
                    <div className={cx("form-right-group")}>
                      <label htmlFor="field_id">* Lĩnh vực</label>
                      <Form.Item
                        className={cx("form-group")}
                        name="field_id"
                        rules={[
                          {
                            required: true,
                            message: "Lĩnh vực không được để trống",
                          },
                        ]}
                      >
                        <Select
                          className={cx("select-group")}
                          placeholder={"Select field"}
                          onChange={handleChangeWards}
                        >
                          {fieldData.map((item) => {
                            return <Option value={item.id}>{item.name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className={cx("form-left")}>
                    <div className={cx("form-left-group")}>
                      <label htmlFor="phone">{t("register.form1.phone")}</label>
                      <Form.Item
                        className={cx("form-group")}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Số điện thoại không được để trống",
                          },
                          {
                            pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
                            message: "Số điện thoại không đúng định dạng",
                          },
                        ]}
                      >
                        <Input
                          className={cx("input-3")}
                          placeholder={t("text.enter_phone_number")}
                        />
                      </Form.Item>
                    </div>
                    <div className={cx("form-left-group")}>
                      <label htmlFor="website">{t("text.website_if")}</label>
                      <Form.Item className={cx("form-group")} name="website">
                        <Input
                          className={cx("input-3")}
                          placeholder={t("text.enter_website")}
                        />
                      </Form.Item>
                    </div>

                    <div className={cx("form-left-group")}>
                      <label htmlFor="personal_number">
                        {t("register.form1.tax")}
                      </label>
                      <Form.Item
                        className={cx("form-group")}
                        name="personal_number"
                        rules={[
                          {
                            required: true,
                            message: "Mã số thuế không được để trống",
                          },
                        ]}
                      >
                        <Input
                          className={cx("input-3")}
                          placeholder={t("text.enter_tax_code")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className={cx("stall-details")}>
                  <h3>{t("text.Details")}</h3>
                  <div className={cx("stall-form-item")}>
                    <div className={cx("stall-form-item-top")}>
                      <div className={cx("form-right")}></div>
                      <div className={cx("form-left")}></div>
                    </div>
                    <div className={cx("stall-form-item-bottom")}>
                      <div className={cx("form-bottom-group")}>
                        <label htmlFor="des">{t("text.description")}</label>
                        <Form.Item
                          className={cx("form-group")}
                          name="des"
                          rules={[
                            {
                              required: true,
                              message: "Mô tả không được để trống",
                            },
                          ]}
                        >
                          <Input.TextArea
                            className={cx("input-textarea")}
                            placeholder={t("text.enter_description")}
                          />
                        </Form.Item>
                        <Form.Item
                          name="cities"
                          rules={[
                            {
                              required: true,
                              message: "Tên Tỉnh/Thành phố không được để trống",
                            },
                          ]}
                        >
                          <Select
                            className={cx("select-group")}
                            placeholder={t("text.province/city")}
                            onChange={handleChangeCity}
                          >
                            {allCities.map((item) => (
                              <Option
                                key={item.id}
                                value={item.id}
                                ghn_id={item.ghn_id}
                              >
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="district"
                          rules={[
                            {
                              required: true,
                              message: "Tên Quận/Huyện không được để trống",
                            },
                          ]}
                        >
                          <Select
                            className={cx("select-group")}
                            placeholder={t("text.district")}
                            onChange={handleChangeDistrict}
                          >
                            {listDistricts.map((item) => (
                              <Option
                                key={item.id}
                                value={item.id}
                                ghn_id={item.ghn_id}
                              >
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="ward"
                          rules={[
                            {
                              required: true,
                              message: "Tên Xã/Phường không được để trống",
                            },
                          ]}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Select
                            className={cx("select-group")}
                            placeholder={t("text.wards")}
                            onChange={handleChangeWards}
                          >
                            {listWards.map((item) => (
                              <Option
                                className={cx("option-address")}
                                key={item.id}
                                value={item.id}
                                ghn_id={item.ghn_id}
                              >
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx("image-desc")}>
                  <span className={cx("title-image-desc")}>
                    <Form.Item
                      name="image_url"
                      rules={[
                        {
                          required: true,
                          message: "Avatar không được để trống",
                        },
                      ]}
                    >
                      <div className={cx("main-form_item", "w100")}>
                        {storeData.image_url === null ||
                        storeData.image_url?.length === 0
                          ? isEmpty
                          : isNotEmpty(storeData.image_url)}
                      </div>
                    </Form.Item>
                  </span>
                </div>
                <div className={cx("action-submit")}>
                  <button type="submit">
                    {isEdit ? "Lưu thay đổi" : t("text.create_store")}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateBusiness;

import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import enterpriseApi from "~/api/Enterprise/EnterpriseApi";

const RegStore = ({ eventType }) => {
  const { Option } = Select;
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const onSubmit = async (value) => {
    const dataSubmit = {
      ...value,
      category: category,
      event_id: parseInt(id),
      type: eventType ? 2 : 1,
    };

    const res = await enterpriseApi.regContact({ dataSubmit });
    console.log("res", res);
  };

  const handleChangeCate = (e, name) => {
    const cateData = name.map((item) => {
      return {
        id: item.value,
        name: item.name,
      };
    });
    setCategory(cateData);
  };

  console.log("catedata", category);

  return (
    <div className="reg-store-wrapper">
      <Form name="form-reg-store" layout="vertical" onFinish={onSubmit}>
        <h2 className="primary-color title-spacing">Thông tin doanh nghiệp</h2>
        <Row gutter={[60, 60]}>
          <Col span={12}>
            <Form.Item
              name="name_enterprise"
              label="Tên doanh nghiệp"
              rules={[
                {
                  required: true,
                  message: "Tên doanh nghiệp không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập tên doanh nghiệp" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tax_code"
              label="Mã số thuế"
              rules={[
                {
                  required: true,
                  message: "Mã số thuế không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập mã số thuế" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[60, 60]}>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Địa chỉ"
              rules={[
                {
                  required: true,
                  message: "Địa chỉ không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập địa chỉ" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="website"
              label="Website"
              rules={[
                {
                  required: true,
                  message: "Website không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[60, 60]}>
          <Col span={12}>
            <Form.Item
              name="phone_enterprise"
              label="Số điện thoại"
              rules={[
                {
                  required: true,
                  message: "Số điện thoại không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        <h2 className="primary-color title-spacing">Thông tin người liên hệ</h2>
        <Row gutter={[60, 60]}>
          <Col span={12}>
            <Form.Item
              name="full_name"
              label="Họ tên"
              rules={[
                {
                  required: true,
                  message: "Họ tên không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập họ tên" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="position"
              label="Chức vụ"
              rules={[
                {
                  required: true,
                  message: "Chức vụ không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập chức vụ" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[60, 60]}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Số điện thoại"
              rules={[
                {
                  required: true,
                  message: "Số điện thoại không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        <h2 className="primary-color title-spacing">
          Thông tin gian hàng trưng bày
        </h2>
        <Row gutter={[60, 60]}>
          <Col span={12}>
            <Form.Item
              name="name_department"
              label="Tên gian hàng"
              rules={[
                {
                  required: true,
                  message: "Tên gian hàng không được để trống",
                },
              ]}
            >
              <Input placeholder="Nhập tên gian hàng" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Danh mục"
              rules={[
                {
                  required: true,
                  message: "Danh mục không được để trống",
                },
              ]}
            >
              <Select
                placeholder="Chọn danh mục"
                mode="tags"
                onChange={(e, name) => handleChangeCate(e, name)}
              >
                <Option value={1} name="Đồ uống">
                  Đồ uống
                </Option>
                <Option value={2} name="Thức ăn">
                  Thức ăn
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="action-submit">
          <Button type="primary" htmlType="submit">
            Đăng ký
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RegStore;

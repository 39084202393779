import classNames from "classnames/bind";
import { useState } from "react";
import Competition from "./Components/Competition";
import styles from "./Events.module.scss";
import Competition2 from "./Components/Competition2";
import { useTranslation } from "react-i18next";
const cx = classNames.bind(styles);

function Events() {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const MENU = [
    {
      id: 1,
      title: t("text.WORKSHOPS"),
    },
    {
      id: 2,
      title: t("text.Competition"),
    },
  ];
  const list = MENU.map((item, index) => {
    return (
      <div
        key={index}
        className={current === item.id ? `${cx("list-item")}` : `${cx("abc")}`}
        style={
          current === item.id
            ? { borderBottom: "2px solid #25457C", paddingBottom: 3 }
            : { color: "#cbcbcb" }
        }
        onClick={() => setCurrent(item.id)}
      >
        {item.title}
      </div>
    );
  });
  return (
    <div className={cx("wrapper")}>
      <div className={cx("switch")}>{list}</div>
      <div className={cx("content")}>
        {current === 1 ? (
          <>
            <Competition eventType={current} />
          </>
        ) : null}
        {current === 2 ? (
          <>
            <Competition2 eventType={current} />
          </>
        ) : null}
        {current === 3 ? <div>component3</div> : null}
      </div>
    </div>
  );
}

export default Events;

import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { faPhoneVolume, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeadlessTippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllCities } from "~/store/CustomerProfile/customerSlice";
import { getAllField } from "~/store/Store/storeSlice";
import { getAllEnterprise } from "~/store/Enterprise/enterpriseSlice";
import { Wrapper } from "~/components/layouts/components/Popper";
import ContactForm from "./ContactForm";
import "./Advise.scss";

const { Option } = Select;

const Advise = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showFormContact, setShowFormContact] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState();
  const [dataSearch, setDataSearch] = useState({
    field_id: "",
    city_id: "",
    name: "",
  });
  const { allCities } = useSelector((state) => state.customerReducer);
  const { listField } = useSelector((state) => state.storesReducer);
  const { enterpriseList, loadingEnterprise } = useSelector(
    (state) => state.enterpriseReducer
  );
  useEffect(() => {
    dispatch(getAllCities());
    dispatch(getAllField());
    dispatch(getAllEnterprise());
  }, []);

  const checkImage = (arrImage) => {
    if (arrImage === null) return null;
    else return process.env.REACT_APP_API_URL + arrImage[0]?.url;
  };

  const onChangeSearch = (e) => {
    setDataSearch((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSearchConsultant = () => {
    dispatch(getAllEnterprise(dataSearch));
    setShowSearch(false);
  };

  return (
    <div className="advise-wrapper" id="advise-activities">
      <Spin spinning={loadingEnterprise}>
        <div className="inner">
          <div className="content-main-advise">
            {/* <div className="search-group">
              <div className="search-btn">
                <HeadlessTippy
                  zIndex={1}
                  interactive
                  visible={showSearch}
                  placement="bottom-start"
                  render={(attrs) => (
                    <Wrapper tabIndex="-1" {...attrs}>
                      <div className="search-group">
                        <div className="search-field">
                          <Select
                            style={{
                              width: "320px",
                            }}
                            className="select-group"
                            placeholder="Chọn lĩnh vực"
                            onChange={(value) => {
                              setDataSearch({
                                ...dataSearch,
                                field_id: value,
                              });
                            }}
                          >
                            <Option value="">Tất cả</Option>
                            {listField.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="search-location">
                          <Select
                            style={{
                              minWidth: "320px",
                              zIndex: 1000000,
                            }}
                            className="select-group"
                            placeholder="Chọn địa bàn"
                            onChange={(value) => {
                              setDataSearch({
                                ...dataSearch,
                                city_id: value,
                              });
                            }}
                          >
                            <Option value="">Tất cả</Option>
                            {allCities.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className="search-input">
                          <Input
                            style={{
                              minWidth: "320px",
                            }}
                            name="name"
                            placeholder="Nhập từ khóa"
                            onChange={onChangeSearch}
                          />
                        </div>
                        <div className="btn-search">
                          <Button
                            onClick={handleSearchConsultant}
                            type="primary"
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                    </Wrapper>
                  )}
                >
                  <button
                    onClick={() => {
                      setShowSearch(!showSearch);
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} /> {t("text.search")}
                  </button>
                </HeadlessTippy>
              </div>
            </div> */}

            <div className="consultants-wrap">
              <div className="consultants-list">
                {enterpriseList.length === 0 ? (
                  <h2 className="primary-color title-spacing">
                    Không có nhà tư vấn nào phù hợp
                  </h2>
                ) : (
                  enterpriseList?.map((item, index) => {
                    return (
                      <div className="consultants-item" key={index}>
                        <div className="consultants-image">
                          <img src={checkImage(item.image_url)} alt="Avatar" />
                        </div>
                        <div className="consultants-details">
                          <div className="consultants-details__left">
                            <span className="consultants-details__name">
                              {item.name}
                            </span>
                            <span className="consultants-details-grey">
                              {item.name_of_exchange}
                            </span>
                            <span className="consultants-details-grey">
                              Mã số thuế:{" "}
                              <span style={{ color: "#838383" }}>
                                {item.tax_code}
                              </span>
                            </span>
                            <span className="consultants-details-grey">
                              Số điện thoại:{" "}
                              <span style={{ color: "#838383" }}>
                                {item.phone}
                              </span>
                            </span>
                            <span className="consultants-details-grey">
                              Địa chỉ:{" "}
                              <span style={{ color: "#838383" }}>
                                {item.address}
                              </span>
                            </span>
                            <span className="consultants-details-grey">
                              Lĩnh vực:{" "}
                              <span style={{ color: "#838383" }}>
                                {item.field_id.name}
                              </span>
                            </span>
                          </div>
                          <div className="consultants-details__right">
                            <button
                              onClick={() => {
                                setShowFormContact(true);
                                setEnterpriseId(item.id);
                              }}
                              className="consultants-details__action"
                              //   style={{ background: " #25457C", color: "white" }}
                            >
                              <FontAwesomeIcon
                                // style={{ color: "white" }}
                                icon={faCommentDots}
                              />
                              <span>Liên hệ</span>
                            </button>
                            <button
                              className="consultants-details__action1"
                              //   style={{ color: " #25457C" }}
                            >
                              <FontAwesomeIcon
                                // style={{ color: " #25457C" }}
                                icon={faPhoneVolume}
                              />
                              <span>Gọi điện</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              <Modal
                name="modal-contact"
                visible={showFormContact}
                onCancel={() => setShowFormContact(false)}
                width={700}
                footer={null}
              >
                <ContactForm enterpriseId={enterpriseId} />
              </Modal>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Advise;

import { Modal } from "antd";
import React, { useState } from "react";
import RegCompetition from "../../Activities/Participate/RegCompetition";
import "./CardEvent.scss";
import formatCash from "~/utils/formatCash";

const CardEvent = ({ item }) => {
  const [showModalRegEvent, setShowModalRegEvent] = useState(false);
  return (
    <div className="card-event-item">
      <Modal
        visible={showModalRegEvent}
        onCancel={() => setShowModalRegEvent(false)}
        footer={null}
        wrapClassName="modal-register-competition"
        title="Đăng ký tham gia"
        centered
      >
        <RegCompetition idCompetition={item} />
      </Modal>
      <div className="image-group">
        <img
          src={`${process.env.REACT_APP_API_URL}${item?.image_url[0]?.url}`}
          alt="Error"
        />
        <div className="area">Hồ Chí Minh</div>
      </div>
      <div className="card-event-content">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="title">{item?.name}</div>
          <div>
            <div className="date-group">
              <div style={{ color: "green" }} className="price">
                {item?.cost === 0 ? (
                  "Miễn phí"
                ) : (
                  <span style={{ color: "red" }}>
                    {" "}
                    {formatCash(item?.cost?.toString())}đ
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className="date-time"
            style={{ color: "black", fontSize: "16px" }}
          >
            Thời gian:{" "}
            <span style={{ color: "#828282" }}>
              {item?.start_time?.substring(0, 10)}
            </span>
          </div>

          <div className="category-group">
            <div className="category" style={{ color: "black" }}>
              Hình thức:
              <span style={{ color: "#828282" }}>
                {item?.type === 3 ? " Cuộc thi" : " Hội thảo"}
              </span>
            </div>
          </div>
          <div className="action-btn">
            <button
              onClick={() => {
                setShowModalRegEvent(true);
              }}
            >
              Đăng ký ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEvent;

import { Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";

import "./RegCompetition.scss";
import eventsApi from "~/api/eventsApi";
import ButtonComponent from "~/components/layouts/components/Button";

const RegCompetition = ({ visible, idCompetition, eventType }) => {
  const { t } = useTranslation();

  const validateMessages = {
    required: "${label} không được để trống!",
    types: {
      email: "${label} không đúng định dạng!",
      number: "${label} không đúng định dạng!",
    },
  };

  const handleExit = () => {
    visible(false);
  };

  const onFinish = async (values) => {
    const newData = {
      contact_name: values.contact_name.trim(),
      phone: values.phone.trim(),
      email: values.email.trim(),
      address: values.address.trim(),
      payment_method: 0,
      start_date: idCompetition.start_time,
      end_date: idCompetition.end_time,
      type: idCompetition.type === 4 ? 5 : 6,
      list_details: [
        {
          event_id: idCompetition.id,
        },
      ],
    };

    const res = await eventsApi.registerEvent(newData);
    if (res.message === "error") {
      message.error(t("text.register_failed"));
    } else if (res.message === "Booking Event Exist") {
      message.error(t("text.account_exists"));
    } else if (res.id === undefined) {
      message.success(t("text.registration_success"));
    }
  };

  return (
    <div className="reg-competition-wrap">
      <Form
        onFinish={onFinish}
        style={{
          width: "100%",
        }}
        validateMessages={validateMessages}
      >
        <Form.Item
          className="detail-name"
          name="contact_name"
          label="Họ và tên"
          rules={[
            {
              required: true,
              message: "Họ và tên không được để trống",
            },
          ]}
          style={{
            width: "100%",
          }}
        >
          <Input placeholder="Nhập tên" />
        </Form.Item>
        <Form.Item
          className="detail-phone"
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              pattern: /((09|03|07|08|05)+([0-9]{8})\b)/g,
              message: "Số điện thoại không đúng định dạng",
            },
          ]}
        >
          <Input
            style={{
              width: "100%",
            }}
            placeholder="Nhập số điện thoại"
          />
        </Form.Item>
        <Form.Item
          className="detail-phone"
          name="address"
          label="Địa chỉ"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Nhập địa chỉ" />
        </Form.Item>
        <Form.Item
          className="detail-phone"
          name="email"
          label="Email"
          rules={[
            {
              required: true,
            },
            { type: "email" },
          ]}
        >
          <Input placeholder="Nhập địa chỉ E-mail" />
        </Form.Item>
        <div className="register-btn">
          <ButtonComponent
            style={{
              //   background: " #25457C",
              borderRadius: "30px",
              padding: "8px 24px",
              fontSize: "16px",
            }}
            outline
            small2
            onClick={handleExit}
          >
            Trở lại
          </ButtonComponent>
          <ButtonComponent
            primary
            style={{
              background: " #25457C",
              borderRadius: "30px",
              padding: "8px 24px",
              fontSize: "16px",
            }}
            small2
          >
            Đăng ký
          </ButtonComponent>
        </div>
      </Form>
    </div>
  );
};

export default RegCompetition;

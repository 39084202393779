import { useEffect } from "react";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventsWithPag,
  getAllEventsWithPag2,
} from "~/store/Categories/eventSlice";

import InvestmentFund from "./InvestmentFund";
import ShoppingMall from "./ShoppingMall";
import Purpose from "./PurposeIntro";
import CardEvent from "./CardEvent";
import "./Introduce.scss";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import techCap from "../../../assets/images/logo/tech.png";
import vinaCap from "../../../assets/images/logo/vinaCap.png";
function Introduce() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventListPag, eventListPag2 } = useSelector(
    (state) => state.eventsReducer
  );

  useEffect(() => {
    dispatch(
      getAllEventsWithPag({
        type: 3,
        index: 1,
        size: 3,
      })
    );
    dispatch(
      getAllEventsWithPag2({
        type: 5,
        index: 1,
        size: 2,
      })
    );
  }, [dispatch]);

  const investmentData = [
    {
      id: 1,
      name: "VinaCapital",
      des: "Công ty Quản lý Quỹ VinaCapital (“VCFM”) là một trong những công ty đầu ngành trong lĩnh vực đầu tư tài chính ở Việt Nam. Được thành lập bởi Tập đoàn VinaCapital từ năm 2012 và hoạt động dưới tên Công ty Cổ phần Quản lý Quỹ VinaWealth cho đến trước tháng 07 năm 2017, VCFM được cấp giấy phép bởi Ủy Ban Chứng Khoán Nhà Nước để thành lập và quản lý các quỹ đầu tư, quản lý danh mục đầu tư và cung cấp dịch vụ tư vấn đầu tư.",
      url: "https://vinacapital.com/vi",
      image_url: vinaCap,
    },
    {
      id: 2,
      name: "DragonCapital",
      des: "Công ty Cổ Phần Quản Lý Quỹ Dragon Capital Việt Nam (DCVFM) được thành lập dựa trên sự hợp tác toàn diện giữa hai tổ chức tài chính đầu tư hàng đầu và lâu đời nhất tại Việt Nam là Dragon Capital và Công ty Cổ Phần Quản Lý Quỹ Đầu Tư Việt Nam",
      url: "https://dragoncapital.com.vn/",
      image_url:
        "https://vfmcomvnaz.azureedge.net/dcvfmcomvn/themes/vfm/assets/images/DC_Logo.png",
    },
    {
      id: 3,
      name: "Baoviet Fund",
      des: "Công ty TNHH Quản lý Quỹ Bảo Việt (Baoviet Fund) là đơn vị thành viên 100% vốn của Tập đoàn Bảo Việt. Với nền tảng hơn 50 năm của một Tập đoàn Bảo hiểm lớn mạnh bậc nhất Việt Nam, kinh nghiệm gần 20 năm trong lĩnh vực đầu tư, kết hợp cùng đội ngũ nhân sự thường xuyên được củng cố, được hoàn thiện, Baoviet Fund hiện đã khẳng định được vị thế là một trong những nhà đầu tư tài chính chuyên nghiệp hàng đầu trên thị trường Việt Nam.",
      url: "https://baovietfund.com.vn/",
      image_url: "https://baovietfund.com.vn/content/bvf/images/logo.png",
    },
    {
      id: 4,
      name: "TechcomCapital",
      des: "Được thành lập từ năm 2008, Công ty Cổ phần Quản lý Quỹ Kỹ Thương (“Techcom Capital”) là công ty con thuộc Ngân hàng TMCP Kỹ Thương Việt Nam (“Techcombank”) – một trong những ngân hàng thương mại hàng đầu tại Việt Nam. Mục tiêu của Techcom Capital là cung cấp các dịch vụ quản lý đầu tư và tư vấn tài chính doanh nghiệp bao gồm: tư vấn tái cơ cấu doanh nghiệp, tư vấn huy động vốn và tư vấn M&A.",
      url: "https://www.techcomcapital.com.vn/",
      image_url: techCap,
    },
  ];

  const shoppingMallData = [
    {
      id: 1,
      name: "Vincom Mega Mall Times City",
      address: "Số 458 Minh Khai, Quận Hai Bà Trưng, Hà Nội",
      open_time: "10:00 - 21:30",
      image_url:
        "https://statics.vincom.com.vn/xu-huong/trung-tam-thuong-mai-lon-nhat-viet-nam/vincom-mega-mall-times-city.jpg",
    },
    {
      id: 2,
      name: "Tràng Tiền Plaza",
      address: "24 Hai Bà Trưng, Phường Tràng Tiền, Quận Hoàn Kiếm, Hà Nội.",
      open_time: "10:00 - 21:30",
      image_url:
        "https://trangtienplaza.net/storage/gallery/April2022/on1ydzuBsiUfsDSI1oJg.jpg",
    },
  ];

  return (
    <div className="introduce-warpper">
      <div className="introduce-inner">
        <div className="purpose-wrap">
          <Purpose />
        </div>
      </div>
      {/* <div className="container-wrapper investment-funds ">
        <h2
          className="primary-color title-spacing"
          style={{ fontWeight: "700", fontSize: "28px", textAlign: "center" }}
        >
          QUỸ ĐẦU TƯ
        </h2>
        <div className="list-investment">
          {investmentData.map((item) => {
            return <InvestmentFund item={item} key={item.id} />;
          })}
        </div>
        <div className="button-container">
          <button

          >
            Xem thêm{" "}
            <FontAwesomeIcon className="icon-hover" icon={faArrowRightLong} />
          </button>
        </div>
      </div>
      <div className="shopping-mall">
        <h2
          className="primary-color "
          style={{ fontSize: "28px", fontWeight: "700", textAlign: "center", marginBottom : 40 }}
        >
          HỘI TRỢ TRIỂN LÃM
        </h2>
        <div className="list-shopping">
          <Row gutter={[36, 36]} style={{ width: "100%" }}>
            {[...eventListPag2, ...eventListPag2].map((item, index) => {
              return (
                <Col key={index} span={12}>
                  <ShoppingMall item={item} />
                </Col>
              );
            })}
          </Row>
        </div>
        <button
          onClick={() => {
            navigate("/fair");
          }}
        >
          Xem thêm{" "}
          <FontAwesomeIcon className="icon-hover" icon={faArrowRightLong} />
        </button>
      </div>
      <div className="event container-wrapper">
        <h2
          className="primary-color title-spacing"
          style={{ fontSize: "28px", fontWeight: "700", textAlign: "center" , marginBottom : 30 }}
        >
          HOẠT ĐỘNG, SỰ KIỆN, HỘI THẢO
        </h2>
        <div className="list-event">
          {eventListPag.map((item) => {
            return <CardEvent item={item} key={item.id} />;
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => {
              navigate("/semilar");
            }}
          >
            Xem thêm{" "}
            <FontAwesomeIcon className="icon-hover" icon={faArrowRightLong} />
          </button>
        </div>
      </div> */}
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-0.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-1.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-2.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-3.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-4.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-5.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-6.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-7.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-8.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-9.jpg" />
      <img width={1902} height={1080} src="/introduce/Times_Zones_Innovation_Infrastructure_images-10.jpg" />
    </div>
  );
}

export default Introduce;

import { message, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import onePayApi from "~/api/Onepay/onepay";
import packageApi from "~/api/Package/packageApi";
import { getAllPackage } from "~/store/Package/packageSlice";
import formatCash from "~/utils/formatCash";
import "./Cooperate.scss";

const Cooperate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user =
    localStorage.getItem("user") === null
      ? null
      : JSON.parse(localStorage.getItem("user"));

  const packageDetail =
    localStorage.getItem("package") === null
      ? null
      : JSON.parse(localStorage.getItem("package"));

  const { listPackage } = useSelector((state) => state.packageReducer);
  // const listPackage = [
  //   {
  //     name: "Premium",
  //     price: 7000000,
  //     time_limit: 360,
  //     des: [
  //       "Được phép xem nội dung các đơn vị theo lĩnh vực mà khách hàng lựa chọn.",
  //       "Được giảm 15% phí thành viên trong năm đầu sử dụng.",
  //       "Được giảm 20% phí thành viên trong năm sử dụng tiếp theo.",
  //       "Tạo sự kiện, thời gian bắt đầu và kết thúc, có áp dụng Flash sale hay không.",
  //       "Tự động hóa tiếp thị với khách hàng theo sự kiện.",
  //       " Yêu cầu giải quyết tranh chấp bằng hòa giải hoặc trọng tài tại Trung tâm Trọng tài Quốc tế Hà Nội.",
  //       "Yêu cầu đổi trả sản phẩm: quản lý người bán, người mua và sàn thương mại điện tử",
  //     ],
  //   },
  //   {
  //     name: "Pro ",
  //     price: 5000000,
  //     time_limit: 360,
  //     des: [
  //       "Được phép xem thông tin của các đơn vị (số lượng, lĩnh vực..) mà khách hàng yêu cầu.",
  //       "Được giảm 10% phí thành viên trong năm đầu sử dụng.",
  //       "Được giảm 15% phí thành viên trong năm sử dụng tiếp theo.",
  //       "Yêu cầu giải quyết tranh chấp bằng hòa giải hoặc trọng tài tại Trung tâm Trọng tài Quốc tế Hà Nội",
  //       "Tự động hóa tiếp thị với khách hàng ",
  //     ],
  //   },
  //   {
  //     name: "Tiêu chuẩn ",
  //     price: 2500000,
  //     time_limit: 365,
  //     des: [
  //       " Được phép xem thông tin của 200 đơn vị bất kỳ.",
  //       "Được giảm 10% phí thành viên trong năm đầu sử dụng.",
  //       "Được giảm 15% phí thành viên trong năm sử dụng tiếp theo.",
  //       "Nhận thông báo về các chương trình ưu đãi ",
  //       "Miễn phí giao hàng, kết nối giao hàng nhanh, giao hàng tiết kiệm ",
  //       "Thiết lập chế độ tích điểm,sử dụng điểm để mua hàng hóa và dịch vụ",
  //     ],
  //   },
  //   {
  //     name: "Cơ bản",
  //     price: 0,
  //     time_limit: 30,
  //     des: [
  //       "Được phép xem thông tin của 50 đơn vị bất kỳ.",
  //       "Được giảm 5% phí thành viên trong năm đầu sử dụng.",
  //       " Nhận thông báo về các chương trình ưu đãi",
  //       " Kết nối giao hàng nhanh, giao hàng tiết kiệm",
  //     ],
  //   },
  // ];
  useEffect(() => {
    dispatch(getAllPackage());
  }, [dispatch]);

  const handleRegPackage = async (item, used) => {
    if (used === undefined) {
      if (packageDetail === "Bạn chưa đăng kí gói nào") {
        const data = {
          cooperation_package_id: item.id,
        };
        const res = await packageApi.registerPackage(data);
        if (res.id !== undefined) {
          const dataCheckout = {
            order: {
              Id: res?.id.toString(),
              amount:
                item.id === 3
                  ? 1000000
                  : item.id === 4
                  ? 3000000
                  : item.id === 5
                  ? 7000000
                  : 0,
              customerId: user.id.toString(),
              type: 2,
            },
            transactionType: "domestic",
          };
          const resOnepay = await onePayApi.checkoutOnePay(dataCheckout);
          if (resOnepay.message === "error") {
            message.error(t("text.Payment_failed"));
          } else {
            window.location.href = resOnepay.url;
          }
        }
      } else {
        const data = {
          cooperation_package_id: item.id,
        };
        const res = await packageApi.changePackage(data);
        if (res.id !== undefined) {
          const dataCheckout = {
            order: {
              Id: res?.id.toString(),
              amount:
                item.id === 3
                  ? 1000000
                  : item.id === 4
                  ? 3000000
                  : item.id === 5
                  ? 7000000
                  : 0,
              customerId: user.id.toString(),
              type: 2,
            },
            transactionType: "domestic",
          };
          const resOnepay = await onePayApi.checkoutOnePay(dataCheckout);
          if (resOnepay.message === "error") {
            message.error(t("text.Payment_failed"));
          } else {
            window.location.href = resOnepay.url;
          }
        }
      }
    }
    if (used === "used") {
      message.info("Bạn đang sử dụng gói này");
    }
  };

  return (
    <div className="cooperate-wrapper">
      <div className="inner">
        <div className="cooperate__content-main">
          <div className="investment-funds-group">
            <div className="cooperate__title">
              <h1>{t("text.Connectup_Investment_Fund")}</h1>
              <span>{t("text.Choose_a_product")}</span>
            </div>

            <div className="month-year-btn-group"></div>

            <div className="investments-list">
              {listPackage.map((item, index) => {
                if (index <= 3) {
                  if (packageDetail === "Bạn chưa đăng kí gói nào") {
                    return (
                      <div className="investments-item" key={item.id}>
                        <div className="investments-image">
                          <img
                            src="https://www.readitbro.com/wp-content/uploads/2020/06/types-of-technology.jpg"
                            alt="Avatar"
                          ></img>
                          <span className="investments-funds-name">
                            {t("text.Connectup_member_Service")}
                          </span>
                        </div>
                        <div className="investments-details">
                          <div className="investments-details__content">
                            <span className="investments-details__title">
                              {t("text.Special_Offers")}
                            </span>
                            <ul>
                              {/* <li>{t("text.courses_manage")}</li>
                              <li>{t("text.Post_product_to_store")}</li>
                              <li>{t("text.Attract_investors")}</li>
                              <li>{t("text.Join_national_events")}</li>
                              <li>
                                {t("text.Join_ConnectUp_training_courses")}
                              </li> */}
                            </ul>
                          </div>
                          <div className="investments-details__bottom">
                            <div className="investments-details__price">
                              <span>{formatCash(item?.price.toString())}</span>/
                              <span>
                                {item.time_limit === 30
                                  ? `1 ${t("text.month")}`
                                  : item.time_limit === 180
                                  ? `6 ${t("text.month")}`
                                  : `1 ${t("text.year")}`}
                              </span>
                            </div>
                            <div className="investments-details__actions">
                              <Popconfirm
                                title="Are you sure you want to sign up?"
                                onConfirm={() => handleRegPackage(item)}
                              >
                                <button className="register-now">
                                  {t("login.register")}
                                </button>
                              </Popconfirm>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (packageDetail !== "Bạn chưa đăng kí gói nào") {
                    return (
                      <div className="investments-item" key={item.id}>
                        {/* <div className="investments-image">
                                                    <img
                                                        src="https://www.readitbro.com/wp-content/uploads/2020/06/types-of-technology.jpg"
                                                        alt="Avatar"
                                                    ></img>
                                                    <span className="investments-funds-name">
                                                        {t('text.Connectup_member_Service')}
                                                    </span>
                                                </div> */}
                        <div className="investments-details">
                          <div className="investments-details__content">
                            <span className="investments-details__title">
                              {/* {t("text.Special_Offers")} */}
                              {item.name}
                            </span>
                            <ul>
                              {item?.des?.map((data) => {
                                return <li>{data}</li>;
                              })}
                              {/* <li>{t("text.courses_manage")}</li>
                              <li>{t("text.Post_product_to_store")}</li>
                              <li>{t("text.Attract_investors")}</li>
                              <li>{t("text.Join_national_events")}</li>
                              <li>
                                {t("text.Join_ConnectUp_training_courses")}
                              </li> */}
                            </ul>
                          </div>
                          <div className="investments-details__bottom">
                            <div className="investments-details__price">
                              {item?.price === 0 ? (
                                <>FREE</>
                              ) : (
                                <>
                                  <span>
                                    {formatCash(item?.price?.toString())}
                                  </span>
                                  /
                                  <span>
                                    {item.time_limit === 30
                                      ? `1 ${t("text.month")}`
                                      : item.time_limit === 180
                                      ? `6 ${t("text.month")}`
                                      : `1 ${t("text.year")}`}
                                  </span>
                                </>
                              )}
                            </div>
                            <div className="investments-details__actions">
                              <Popconfirm
                                title="Are you sure you want to sign up?"
                                onConfirm={() => handleRegPackage(item)}
                              >
                                <button className="register-now">
                                  {t("login.register")}
                                </button>
                              </Popconfirm>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                } else {
                  return <></>;
                }
              })}
            </div>

            {/* <div className="cooperate-documents">
              <div className="cooperate-documents__title">
                <span>{t("text.Relevant_material")}</span>
              </div>
              <div className="cooperate-documents__files">
                <div className="documents">{t("text.Quotation")}</div>
                <div className="documents">{t("text.User_Agreement")}</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cooperate;
